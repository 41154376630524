.sign-in-form {
  border-radius: 20px;
  padding: 20px;
  max-width: 300px;
  width: 100%;
  border: 3px solid black;

  &__footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    p {
      margin: 0
    }
    .forgot-password{
      cursor: pointer;
      color: #0d6efd;
    }
  }
}