html,
body {
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
  Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

@font-face {
  font-family: bpg-web-002-caps;
  src: url("../public/fonts/bpg-web-002-caps-webfont.ttf");
}

@font-face {
  font-family: TBC-Contractica;
  src: url("../public/fonts/TBCContracticaCAPS-Regular.ttf");
}

@font-face {
  font-family: FiraGO-Regular;
  src: url("../public/fonts/FiraGO-Regular.otf") ;
}

@font-face {
  font-family: FiraGO-Heavy;
  src: url("../public/fonts/FiraGO-Heavy.otf") ;
}

.modal-header {
  border: 0!important;
  padding: 16px 0!important;
}
.modal-close-btn {
  display: flex;
  justify-content: flex-end;
  border: 0;
  background: transparent;
  width: 100%;
}
a {
  color: inherit;
  text-decoration: none !important;
}

* {
  box-sizing: border-box;
}

p {
  margin: 0;
}

h1 {
  margin: 0;
  font-weight: unset;
}

.user-layout {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}


