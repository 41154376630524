.news-page {
  flex: 1;
  margin: 185px 0;

  h1 {
    color: #233e99;
    font-family: FiraGO-Regular;
    padding: 0 30px 30px;
    max-width: 1680px;
    margin: auto;
  }

  .news-container {
    max-width: 1680px;
    min-height: 460px;
    margin: 0 auto 20px;
    display: flex;
    gap: 25px;
    padding: 0 20px;
    flex-wrap: wrap;
    justify-content: space-between;
    @media (max-width: 885px) {
      justify-content: center;
    }

    .news-card {
      display: flex;
      flex-direction: column;
      width: 410px;
      row-gap: 20px;
    }

    h1 {
      text-align: center;
      font-size: 30px;
      font-weight: 400;
      -webkit-line-clamp: 3;
      line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      padding: 0;
    }

    .img-container {
      position: relative;
      width: 100%;
      height: 269px;
      background-size: cover;
      background-position: 50%;
      background-repeat: no-repeat;
      border-radius: 30px;
      overflow: hidden;
      cursor: pointer;

      span {
        width: 100%;
      }
    }
  }

  .ladder {
    position: absolute;
  }

  .content-box {
    padding: 0 20px;
    display: flex;
    justify-content: center;

    .content {
      max-width: 1340px;
      width: 100%;

      .middle {
        display: flex;
        column-gap: 20px;
      }

      h1 {
        color: #233E99;
        font-size: 36px;
        font-family: FiraGO-Heavy;
        margin-left: 5px;
        margin-bottom: 0;
      }

      p {
        margin-left: 5px;
        font-family: FiraGO-Regular;
        font-size: 16px;
        color: #233E99;
        margin-bottom: 0;
      }


      .news-page-img-container {
        max-width: 850px;
        width: 100%;
        height: auto;
        max-height: 452px;
        object-fit: cover;
        border: 1px solid #707070;
        border-radius: 28px;
        margin: 35px 0;
        cursor: pointer;

      }

      .right {
        display: flex;
        justify-content: center;
        flex-direction: column;
        gap: 28px;
        max-width: 470px;
        width: 100%;
      }
    }

  }

}

.news-page-small-card {
  display: flex;
  gap: 27px;
  height: 132px;

  .news-page-small-img-container {

    max-width: 132px;
    width: 100%;
    max-height: 132px;
    height: 100%;
    object-fit: cover;
    border: 1px solid #707070;
    border-radius: 27px;
    cursor: pointer;
  }

  .text {
    padding-top: 15px;
    display: flex;
    flex-direction: column;

    .description {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
    }

    h1, p {
      font-family: FiraGO-Regular !important;
      color: #1F2023 !important;
      margin: 0 !important;
    }

    h1 {
      font-size: 20px !important;
    }

    p {
      font-size: 16px !important;
    }
  }
}

.singular-news {
  max-width: 1640px;
  width: 100%;
  margin: 240px auto 0 auto;
  flex: 1;

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    margin-bottom: 10px;
    padding: 0 12px;

    &__image {
      position: relative;
      margin: 50px 0;

      & > span {
        border-radius: 28px;
        border: 1px solid #707070 !important;
      }
    }

    &__images {
      margin: 30px 0;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      column-gap: 30px;
      row-gap: 1em;

      & > span {
        border-radius: 28px;
      }
    }

    p {
      font-size: 16px;
      color: #233E99;
      font-family: FiraGO-Regular;
      margin: 0;
      max-width: 650px;
    }

    .head {
      position: relative;
      width: 100%;

      h1 {
        font-size: 36px;
        font-family: FiraGO-Heavy;
        color: #233E99;
        margin-bottom: 26px;
        text-align: center;
      }

      .date {
        position: absolute;
        font-size: 20px;
        right: 0;
        font-family: bpg-web-002-caps;
      }
    }

  }

}

#inside {
  display: none;
  margin-bottom: 20px;
}


@media only screen and (max-width: 1100px) {

  #inside {
    display: unset;

  }
  #outside {
    display: none;
  }

  .news-page > .content-box > .content {

    align-items: center;
    display: flex;
    justify-content: center;
    flex-direction: column;

    .middle {
      flex-direction: column;

      .right {
        flex-direction: row;
        justify-content: flex-start;
        max-width: unset;

        .key {
          max-width: 300px;
          width: 100%;
        }
      }
    }


  }

}
