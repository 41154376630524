.team-page {
  flex: 1;
  margin-top: 240px;
  display: flex;
  flex-direction: column;
  gap: 147px;
  padding-bottom: 132px;
  overflow: hidden;

  .team {
    max-width: 1400px;
    width: 100%;
    padding-right: 12px;
    padding-left: 60px;
    margin: 0 auto;
    transition: 0.3s ease-out;
    position: relative;

    h1 {
      font-size: 36px;
      font-family: FiraGO-Heavy;
      color: #233E99;
      margin: 0;
    }

    .middle {
      display: flex;
      gap: 75px;
      margin: 20px 0 74px 0;

      img {
        border-radius: 41px;
        box-shadow: 5px 8px 6px #00000036;
        width: 100%;
        max-width: 706px;
        max-height: 406px;
        height: auto;
        -o-object-fit: cover;
        object-fit: cover;
      }

      p {
        margin-top: 70px;
        color: #233E99;
        font-size: 16px;
        font-family: TBC-Contractica;
      }

    }

    .members {
      display: flex;
      max-width: 1189px;
      width: 100%;
      justify-content: space-between;
      padding-left: 120px;
      padding-right: 12px;

      .member {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 36px;

        &__img {
          position: relative;
          width: 157px;
          min-height: 157px;

          & > * {
            border-radius: 50%;
            border: 1px solid #707070;

          }
        }

        p {
          font-family: TBC-Contractica;
          font-size: 20px;
          color: #1F2023;
        }
      }
    }

    .deco {
      position: absolute;

      &.one {
        left: -485px;
        bottom: -40px;
      }

      &.two {
        right: -240px;
        bottom: -100px;
      }

      &.three {
        left: -220px;
        bottom: -50px;
      }

      &.four {
        right: -240px;
        bottom: -70px;
      }
    }
  }

  .team.reverse {
    h1 {
      text-align: end;
    }

    .middle {
      flex-direction: row-reverse;
    }
  }
}


@media only screen and (max-width: 1400px) {
  .team-page {
    .team {
      padding: 0 12px;
      transition: 0.3s ease-out;

      .deco {
        display: none;
      }
    }

  }
}