.admin-layout {
  display: flex;
}

.admin-layout > .content {
  width: 100%;
  margin-left: auto;
  padding: 10px;
  display: flex;
  flex-direction: column;
}

.closemenu {
  color: #000;
  z-index: 9999;
  line-height: 20px;
  border-radius: 50%;
  font-weight: bold;
  font-size: 35px;
  cursor: pointer;
}


.pro-sidebar .pro-menu a {
  color: black !important;
}

.admin-layout > .content > .home {
  display: flex;
  flex-direction: column;
  gap: 15px;

  .header {
    display: flex;
    justify-content: space-between;
    gap: 10px;

  }

  .selectable-dropdown {
    width: 200px;
    margin-left: auto;
  }
}

.custom-table-rows {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
}

.admin-add-button {
  min-width: 150px;
}

.form-number-input::-webkit-outer-spin-button,
.form-number-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.custom-table-delete {
  cursor: pointer;
  color: red !important;
  width: fit-content;
}

.custom-table-edit {
  color: yellow !important;
}

.file-input-with-preview {
  display: flex;
  gap: 40px;
}

.sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  min-height: 100vh !important;
  z-index: 100;
  padding: 48px 0 0;
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, .1);
}

#sidebar-wrapper {
  min-height: 100vh !important;
  width: 100vw;
  margin-left: -1rem;
  //-webkit-transition: margin .25s ease-out;
  //-moz-transition: margin .25s ease-out;
  //-o-transition: margin .25s ease-out;
  //transition: margin .25s ease-out;
}

#sidebar-wrapper .sidebar-heading {
  padding: 0.875rem 1.25rem;
  font-size: 1.2rem;
}

#page-content-wrapper {
  min-width: 0;
  width: 100%;
}

.collapse:not(.show) {
  display: flex !important;
}

.admin-side-nav-fake.collapsed, .admin-side-nav.collapsed {
  width: 66px;
  //transition: 0.3s ease-out;
}

.admin-side-nav-fake {
  width: 200px;
  //transition: 0.3s ease-out;
}

.admin-side-nav {
  //transition: 0.3s ease-out;
  height: 100vh;
  width: 200px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  position: fixed;
  align-items: center;

  &__header {
    gap: 15px;
    width: 100%;
    display: flex;
    align-items: center;
    border: 2px solid gray;
    border-bottom: none;
    flex-direction: column;
    padding: 15px 0;

    .closemenu {
      text-align: center;
    }

    .logotext {
      border-radius: 50%;
      display: flex;
      position: relative;
      align-items: center;
      gap: 5px;
      flex-direction: column;
      background: black;
      padding: 8px;
      cursor: pointer;

      #avatar-overlay {
        display: none;
        left: 40px;
        position: absolute;
        background-color: white;
        margin-left: 10px;
        padding: 5px;
        border-radius: 5px;
      }

      svg {
        color: white;
      }

      &:hover {
        #avatar-overlay {
          display: unset;
        }
      }
    }

  }

  &__content {
    width: 100%;
    border: 2px solid gray;
    display: flex;
    height: 100vh;
    flex-direction: column;
    margin-bottom: auto;
    align-items: center;
  }

  &__footer {
    width: 100%;
    height: 81px;
    border: 2px solid gray;
    border-top: none;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.admin-side-nav__content > .menu {
  padding: 5px 0;
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 95%;

  .menu-item {
    display: flex;
    width: 100%;
    align-items: center;
    color: #2a3746;
    gap: 10px;
    padding: 5px;
    justify-content: flex-start;
    cursor: pointer;

    p {
      color: #2a3746;
      font-weight: bold;
      margin: 0;
    }

    .logo-box {
      padding: 6px;
      border: 2px solid;
      border-radius: 7px;
      margin-left: 4px;

    }
  }

  .menu-item.active, .menu-item:hover {
    background-image: linear-gradient(0deg, #100 0%, #100 100%);

    .logo-box {
      border-color: white;

      svg {
        color: white;
      }
    }

    p {
      color: white;
    }
  }
}

.admin-side-nav__footer > .menu-item {
  display: flex;
  gap: 10px;
  cursor: pointer;

  p {
    color: black;
    margin: 0;
  }
}

.admin-error-field {
  color: red;
}

.admin-home-youtube {
  display: flex;
  gap: 20px;

  .new-video {
    &__border {
      width: 300px;
      height: 150px;
      background-color: black;
    }
  }
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.axios-loading {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  z-index: 9999999999;
  animation: fadein 1s linear;
}

.sign-in-page {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-top: 200px;
}

.admin-panel {
  gap: 15px;
  display: flex;
  flex-direction: column;
}

.add-user {
  width: 120px;
}

.closemenu-mobile {
  display: none;
}

.partners-add, .partners-edit {
  h1 {
    text-align: center;
  }
}


.admin-projects-page, .admin-news-page, .admin-applicants-page {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.admin-projects-page, .admin-news-page {
  .header {
    display: flex;
    justify-content: space-between;
    gap: 10px;
  }
}

.admin-applicants-page > .header {
  display: flex;

  code {
    margin-top: 10px;
    margin-bottom: 0;

  }

  .selectable-dropdown {
    width: 200px;
    margin-left: auto;
  }
}

.projects-add, .projects-edit {
  .projects-add-edit-form {
    ol {
      padding-left: 10px;

      li {
        margin-bottom: 10px;
      }
    }

    section {
      margin-left: 10px;
    }

    &__main-image {
      display: flex;
      gap: 100px;

    }
  }
}

.news-add-edit-form__main-image {
  display: flex;
  gap: 100px;
  margin-bottom: 10px;
  align-items: center;
}

.admin-date-range-search {
  display: flex;
  gap: 20px;
}

.admin-applicants-page {
  .header {
    gap: 10px;
    code {
      margin-top: 0;
      height: 28px;
      min-width: 130px;
      width: 130px;
    }
  }

}

@media only screen and (max-width: 900px) {

  .applicants-show-more{
    margin-left: 60px;
    margin-top: 10px;
  }
  .admin-side-nav-fake {
    width: 0;

    .admin-side-nav {
      background-color: white;
      z-index: 2;
    }
  }
  .admin-panel > button {
    margin-left: auto;
  }
  .admin-layout > .content > .home > .header {
    margin-left: 60px;
  }
  .admin-projects-page, .admin-news-page, .admin-applicants-page {
    .header {
      margin-left: 60px;
    }
  }
  .closemenu-mobile {
    display: unset;
    position: absolute;
    top: 20px;
    left: 87px;
  }
  .closemenu-mobile.show {
    z-index: 1;
    left: 220px;
    width: 25px;
    height: 25px;
  }

  .admin-side-nav-fake {
    width: 0 !important;
    transition: none;

  }
  .admin-side-nav-fake.collapsed, .admin-side-nav.collapsed {
    width: 0;
    transform: translateX(-200px);
    transition: none;
  }
  .admin-side-nav-fake, .admin-side-nav {
    width: 200px;
  }
  .admin-side-nav__header .logotext {
    margin-top: 40px;
  }
  .outside-background {
    width: 100%;
    position: absolute;
    right: 0;
  }
  .outside-background.false {
    width: calc(100% - 200px);
    z-index: 2;
    height: 100%;
    //transition: 0.3s ease-out;
    background-color: rgba(105, 105, 105, 0.5);
  }

  .admin-home-youtube {
    flex-direction: column;
  }

  .partner-carousel-simulation {
    display: none;
  }
}
