#scroll-to-top {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  left: 4%;
  bottom: 40px;
  font-size: 3rem;
  z-index: 1;
  cursor: pointer;
  width: 65px;
  height: 65px;
  border-radius: 17px;
  background: #233E99;
}
