.presentation-page {
  z-index: 11;
  flex: 1;
  margin: 180px 40px;
}


@media only screen and (max-width: 1000px) {
  .presentation-page {
    flex: 1;
    margin: 80px 15px 20px 15px;
  }
}