.home-page {

  #about {
    scroll-behavior: smooth;
  }

  .binary-earth-container {
    z-index: -1;
    overflow: hidden;
    width: 717px;
    height: 864px;
    position: absolute;
    top: -258px;
    right: 0;

    .binary-earth {
      position: absolute;
      width: 864px;
      height: 864px;
    }
  }

  .project_swiper {
    padding: 0 50px;
    @media (max-width: 600px) {
      padding: 0;
    }
  }

  .swiper-button-prev {
    left: 15px;
  }

  .swiper-button-next {
    right: 25px;
  }

  .header {
    text-align: center;
    font-size: 60px;
    color: #233E99;
    font-weight: bold;
    margin: auto;
    width: fit-content;

    &.do {
      margin-bottom: 30px;
      font-weight: normal;
    }

    &.projects {
      margin-bottom: 39px;
      cursor: pointer;
      font-weight: normal;
    }

    &.team {
      margin-bottom: 45px;
      cursor: pointer;
      font-weight: normal;
    }

    &.partners {
      margin-top: 112px;
      margin-bottom: 45px;
      font-weight: normal;
    }

    &.hello {
      margin-bottom: 40px;
      font-weight: normal;
    }

    &.software {
      text-align: left;
      margin-left: 0;
      width: 700px;
    }

    &.about {
      font-weight: normal;
    }

    &.news {
      color: white;
      cursor: pointer;
      font-weight: normal;
    }
  }
}

.news-block {
  height: 100vh;
  background-color: #233E99;
  border: 1px solid #707070;
  margin-bottom: 44px;
  padding-top: 23px;
  position: relative;
  transition: 0.3s ease-out;
  min-height: 800px;

  .horn {
    position: absolute;
    width: 196px;
    height: 161px;
    right: 12px;
    top: 59px;
    transition: 0.3s ease-out;

  }

  &__container {
    padding: 0 12px;
    max-width: 1553px;
    width: 100%;
    margin: auto;
    margin-top: 56px;
    display: flex;
    gap: 40px;
    justify-content: space-between;
    transition: 0.3s ease-out;

    .left {
      max-width: 1100px;
      width: 100%;

      .news-img-container {
        cursor: pointer;
        position: relative;
        transition: 0.3s ease-out;
        height: 100%;
        min-height: 480px;
        width: 100%;

        h2 {
          position: absolute;
          bottom: 0;
          left: 10px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          line-clamp: 2;
          -webkit-box-orient: vertical;
          border-radius: 0;
        }

        & > * {
          border-radius: 30px;

          img {
            object-fit: cover !important;
          }
        }
      }


      .show-more {
        color: #233E99;
        background: white;
        padding: 14px 34px;
        border: 2px solid #233E99;
        transition: 0.3s ease-out;
        border-radius: 20px;
        font-size: 20px;
        width: fit-content;
        cursor: pointer;
        font-family: FiraGO-Regular;

        &:hover {
          background: #233E99;
          color: white;
          border-color: white;
        }
      }

      h2 {
        color: white;
        font-size: 25px;
        font-family: bpg-web-002-caps;
        margin-top: 40px;
        margin-bottom: 30px;
        transition: 0.3s ease-out;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
    }

    .right {
      display: flex;
      flex-direction: column;
      gap: 15px;
      transition: 0.3s ease-out;
      max-width: 358px;
      width: 100%;
      @media(max-width: 1000px) {
        margin: auto;
      }

      .news-img-container.secondary {
        position: relative;
        width: 100%;
        height: 20vh;
        min-height: 160px;
        transition: 0.3s ease-out;
        cursor: pointer;

        & > * {
          border-radius: 30px;
        }
      }
    }
  }

}

.about-block {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 357px;
  gap: 40px;
  position: relative;

  .rocket {
    position: absolute;
    left: 0;
    bottom: -370px;
    width: 392px;
    height: 558px;
  }

  .about-container {
    max-width: 1215px;
    padding: 48px 128px 79px 129px;
    box-shadow: 0 3px 6px rgb(0 0 0 / 35%);
    border-radius: 61px;
    margin: 0 12px;
    background-color: rgba(255, 255, 255, 0.57);

    p {
      text-align: center;
      line-height: 1.2;
      margin: 0;
      font-family: TBC-Contractica;
      font-size: 20px;
      color: #233E99;
    }
  }
}

.what-we-do-block {
  transition: 0.3s ease-out;
  position: relative;


  .live-chat {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 158px;
    height: 45px;
    background-color: #233E99;
    border-radius: 20px;
    margin: 0 140px -74px auto;
    cursor: pointer;
    transition: 0.3s ease-out;

    &__icon {
      width: 24px;
      height: 24px;
    }

    &__text {
      font-size: 20px;
      color: white;
      padding-left: 8px;
    }
  }
}

.home-background-wave-container {
  display: none;
  z-index: -1;
  position: absolute;
  transform: translateY(-760px);
  overflow: hidden;
  width: 100%;
  height: 1700px;

  img {
    width: 100%;
    height: 100%;
  }

}

.latest-projects-block {
  margin-top: 65px;
}

.our-team-block {
  margin-top: 70px;

  .softgen-flag {
    position: absolute;
    width: 798px;
    height: 747px;
    z-index: -1;
    transform: translateY(-80px);
  }
}

.partners-block {
  margin-bottom: 290px;

  .partner-boy {
    position: absolute;
    left: calc(100vw - 471px);
    transform: translateY(-138px);
    z-index: -1;
  }
}

.hello-block {
  display: flex;
  align-items: center;
  flex-direction: column;

  a {
    color: #4E4F51;
    font-size: 24px;
    margin: 0;
  }

  a:hover {
    color: #4E4F51;
  }

  .joinOrHire {
    margin-top: 60px;
    margin-bottom: 50px;
    display: flex;
    align-items: center;

    .join, .hire {
      font-size: 24px;
      width: 263px;
      height: 63px;
      border-radius: 27px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      p {
        color: white;
        margin: 0;
      }
    }

    .join {
      background-color: #A71215;
    }

    .hire {
      background-color: #233E99;
    }

    p {
      margin-left: 36px;
      margin-right: 42px;
    }

  }

  .couch_guy {
    position: relative;
    margin-right: auto;
    margin-top: -56px;
  }
}

.providing-reliable-software-block {
  margin: auto;
  display: flex;
  max-width: 1540px;
  padding: 220px 20px 0 20px;
  transition: 0.3s ease-out;
  height: 100vh;
  min-height: 800px;

  .left {
    width: 50%;
    padding-left: 100px;
    position: relative !important;
    transition: 0.3s ease-out;


    .hand {
      position: relative;
      margin-top: 22px;
      left: -525px;
      width: 1102px;
      height: 738px;
    }
  }

  .right {
    width: 50%;
    transition: 0.3s ease-out;

    .container {
      position: relative;
      display: flex;
      justify-content: flex-end;
      top: -60px;
      transition: 0.3s ease-out;

      .blue-circle {
        right: 40px;
        background-color: #233E99;
        width: 450px;
        z-index: -1;
        border-radius: 50%;
        height: 450px;
        position: absolute;
        top: 60px;
      }

      .circle-guy {
        width: 516px;
        height: 516px;
        transform: scaleX(-1);
        z-index: 1;
      }

      .circle-box {
        width: 398px;
        height: 564px;
        position: absolute;
        right: 213px;
        top: 40px;
        z-index: 2;
      }
    }

  }
}

.home-page > div > .cards-box {
  max-width: 1630px;
  margin: auto;
  overflow-x: auto;
  display: flex;
  gap: 40px;
  justify-content: space-between;
  padding: 0 50px;
}

.home-page > div > .cards-box::-webkit-scrollbar {
  display: none;
}

.video-responsive {
  left: 121px;
  z-index: 1;
  position: absolute;
  width: 540px;
  height: 250px;
  top: 310px;
  border-radius: 40px;
  transition: 0.3s ease-out;

}

.responsive-inner {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #262d46;
  border-radius: 40px;
}

.play-button {
  gap: 20px;
  width: 100%;
  margin-top: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .button {
    width: 50px;
    cursor: pointer;
  }

  p {
    color: white;
    font-size: 50px;
    margin: 0;
    font-family: FiraGO-Regular;
  }
}

.video-responsive iframe {
  left: 0;
  top: 0;
  border-radius: 30px;
  min-height: 250px;
  height: inherit;
  width: inherit;
  position: absolute;
}

.project_modal {
  color: #2F5476;
}

.home-show-more-popup {
  padding: 31px 30px 48px 30px;
  max-width: 1036px;
  box-shadow: 0 3px 6px rgb(0 0 0 / 35%);
  border-radius: 61px !important;
  margin: 0 30px;

  &.modal-content.f {
    background: #edf7ff;
  }

  &.modal-content.s {
    background: #d8eefb;
  }

  &.modal-content.t {
    background: #d8e9f9;
  }

  .modal-image {
    height: 320px;

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }

  .modal-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 20px;
    position: relative;
    padding: 0;

    .image {
      //position: relative;
      //width: 100%;
      height: 100%;
    }
  }
}

//.colored-modal .modal-content{
//  background: red;
//}
.aaa {
  background: black;
}

.join-us-modal {
  background-color: transparent;
}

.sheni {
  --bs-backdrop-bg: transparent !important;
}


.join-us-modal > .modal-dialog {
  max-width: 1183px;
  padding: 0 20px;

  .modal-content {
    height: 967px;
    border-radius: 120px;
    background: rgb(237 221 221 / 73%);
    backdrop-filter: blur(25px);

    .puzzle {
      position: absolute;
      top: 427px;
      right: 47px;

      &.hire {
        top: 175px;
        right: 20px;
      }
    }

    .modal-body {
      padding: 138px 20px 0 20px;
      max-width: 900px;
      width: 100%;
      margin: auto;

      h4 {
        color: #233E99;
        font-family: bpg-web-002-caps;
        font-size: 24px;
        margin-bottom: 90px;
      }

      .form {
        display: flex;
        gap: 100px;

        .left {
          display: flex;
          flex-direction: column;
          gap: 51px;
          max-width: 503px;
          width: 100%;

          .group {

            .control {
              max-width: 503px;
              height: 54px;
              padding: 16px 0 15px 30px;
              border: 1px #233E99 solid;
              border-radius: 9px;
              font-size: 22px;
              font-family: bpg-web-002-caps;
            }
          }

          .group.error > .control {
            border: 1px solid red;
          }

          .actions {
            display: flex;
            justify-content: space-between;

            .close {
              width: 200px;
              height: 54px;
              padding-top: 12px;
              color: black;
              font-size: 22px;
              font-family: bpg-web-002-caps;
              background-color: white;
              border: 1px solid #992323;
              border-radius: 9px;
            }

            .submit {
              width: 202px;
              height: 56px;
              padding-top: 12px;
              color: white;
              font-family: bpg-web-002-caps;
              font-size: 22px;
              background-color: #233E99;
              border-radius: 9px;

            }
          }
        }

        .right {
          .attach {
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            width: 198px;
            height: 54px;
            color: white;
            font-family: bpg-web-002-caps;
            font-size: 22px;
            background-color: #3396D8;
            border-radius: 9px;
          }

          .basic-attach {
            display: none;
          }

          .basic-attach > div > div > input {
            width: 198px;

            &::file-selector-button {
              display: flex;
              justify-content: center;
              align-items: center;
              cursor: pointer;
              width: 198px;
              height: 54px;
              color: white;
              padding-top: 4px;
              font-family: bpg-web-002-caps;
              font-size: 22px;
              background-color: #3396D8;
              border-radius: 9px;
              border: 0;
            }

          }
        }
      }
    }
  }
}

.join-us-modal.hire {
  .modal-content {
    background: rgb(200 215 237 / 53%);
  }
}

.user-error-field {
  color: red;
}

@media only screen and (max-width: 90px) {

  .news-block {
    height: 800px;
    transition: 0.3s ease-out;

    .horn {
      scale: 0.7;
      right: -7px;
      top: 10px;
      transition: 0.3s ease-out;

    }

    &__container {
      max-width: 1080px;
      gap: 80px;
      transition: 0.3s ease-out;

      .left {
        .news-img-container {
          width: 700px;
          height: 414px;
          transition: 0.3s ease-out;

        }


        .show-more {
          padding: 10px 25px;
          transition: 0.3s ease-out;
          border-radius: 16px;
          font-size: 16px;
        }

        h2 {
          max-width: 572px;
          font-size: 18px;
          margin-top: 40px;
          margin-bottom: 20px;
          transition: 0.3s ease-out;

        }
      }

      .right {
        gap: 50px;
        transition: 0.3s ease-out;

        .news-img-container.secondary {
          width: 250px;
          height: 148px;
          border-radius: 50px;
          transition: 0.3s ease-out;

        }
      }
    }


  }

}

@media only screen and (max-width: 1450px) {

  .providing-reliable-software-block {
    transition: 0.3s ease-out;
    padding: 100px 15px 0 15px;

    .left, .right {
      transition: 0.3s ease-out;
      transform: scale(0.75);
      padding-left: 0;
    }

    .right .container {
      right: -140px;
      transition: 0.3s ease-out;
    }

    .left {
      margin-left: -50px;
      transition: 0.3s ease-out;

      h1 {
        font-size: 54px;
      }
    }
  }
  .video-responsive {
    left: 21px;
    transition: 0.3s ease-out;
    top: 225px;
  }
  .what-we-do-block .live-chat {
    margin: 0 20px -74px auto;
    transition: 0.3s ease-out;
  }
}

@media only screen and (max-width: 1400px) {

  .home-page > .about-block {

    margin-bottom: 190px;

    .rocket {
      width: 300px;
      height: 427px;
      bottom: -250px;
    }
  }
}

@media only screen and (max-width: 1000px) {


  .home-background-wave-container {
    display: unset;
    transform: translateY(-320px);

    img {
      width: 270%;
      height: 1700px;
      position: relative;
      left: -310px;
    }
  }

  .home-page > .about-block {
    margin-bottom: 80px;

    .rocket {
      display: none;
    }
  }

  .our-team-block > .softgen-flag {
    display: none;
  }
  .home-background-wave {

  }
  .partner-boy {
    display: none;
  }

  .video-responsive {
    display: none;
  }

  .about-block {
    margin-bottom: 60px;

    .about-container {
      padding: 30px 30px;

      p {
        font-size: 12px !important;
        font: unset;
      }
    }
  }

  .what-we-do-block {
    transition: 0.3s ease-out;

    .live-chat {
      margin: 0 24px 53px auto;
    }
  }

  .home-page {
    .header {
      &.do {
        font-size: 41px;
        margin-bottom: 47px;
      }

      &.about {
        font-size: 41px;
      }

      &.projects {
        font-size: 43px;
      }

      &.team {
        font-size: 43px;
        margin-bottom: 66px;

      }

      &.partners {
        font-size: 44px;
        margin-bottom: 50px;
      }

      &.hello {
        font-size: 50px;
        margin-bottom: 78px;
      }
    }

    .binary-earth-container {
      width: 380px;
      height: 605px;
      top: -148px;
      opacity: 0.47;

      .binary-earth {
        width: 605px;
        left: 5px;
        height: 605px;
      }
    }

  }


  .hello-block {

    p {
      font-size: 24px;
    }

    .joinOrHire {
      flex-direction: column;
      gap: 24px;

    }

  }
  .hello-block {
    margin-bottom: 187px;
  }


  .couch_guy {
    display: none;
  }


  .providing-reliable-software-block {
    overflow: hidden;
    justify-content: flex-end;
    margin-bottom: 120px;
    flex-direction: column;
    margin-top: 0;

    .left {
      padding-left: 0;
      margin: 0 auto;
      width: max-content;

      .hand {
        display: none;
      }

      h1 {
        font-size: 40px;
      }
    }

    .right {
      width: unset;
      transform: scale(0.7);
      position: relative;
      right: -55px;


      /* transform: scale(0.7057);
         right: -155px;*/
    }

  }


}

@media only screen and (max-width: 800px) {

  .home-page {
    .header {
      &.partners {
        margin-bottom: -48px;
      }
    }
  }

  .partners-block {
    margin-bottom: 100px;
  }


}

@media only screen and (max-width: 600px) {

  //.home-page > div > .cards-box {
  //  grid-template-columns: repeat(1, 1fr);
  //  gap: 35px;
  //
  //}
}
