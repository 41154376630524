.HomePageCard {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 410px;
  height: 466px;
  justify-content: space-between;
  @media (max-width: 1024px) {
    height: unset;
  }
  .img-container-square {
    position: relative;
    width: 100%;
    height: 269px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    & > span {
      border-radius: 50px;

    }
  }

  .img-container-circle {
    position: relative;
    width: 276px;
    height: 276px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    & > span {
      border-radius: 50%;
    }
  }

  h1 {
    font-size: 30px;
    color: #233E99;
    margin-top: 15px;
    margin-bottom: 12px;
    font-family: FiraGO-Regular;
    font-weight: normal;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    -webkit-line-clamp: 3;
    line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
  }

  .show-more {
    color: #233E99;
    padding: 14px 34px;
    border: 2px solid #233E99;
    transition: 0.3s ease-out;
    border-radius: 20px;
    font-size: 20px;
    width: fit-content;
    cursor: pointer;
    font-family: FiraGO-Regular;
    text-align: center;
  }

  .show-more:hover {
    background-color: #233E99;
    color: white;
    transition: 0.3s ease-out;
  }

  .img.shape-circle {
    margin-bottom: -60px;
    margin-top: -30px;
  }

  .img.shape-square {
    margin-bottom: -10px;
  }

}

.project_swiper .swiper-slide{
  min-width: 255px;
}
@media only screen and (max-width: 1000px) {

  .HomePageCard {
    width: 255px;
    min-width: 255px;
    justify-self: center;


    h1 {
      margin: 9px 0;
      font-size: 19px;
    }

    .show-more {
      font-size: 13px;
      padding: 9px 24px;
    }

    .img-container-circle {
      width: 200px;
      height: 200px;
    }

    .img-container-square {
      width: 100%;
      height: 164px;
    }

  }

}
