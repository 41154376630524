.nav {
  max-width: 1680px;
  width: 100%;
  padding: 0 20px !important;
  display: flex;
  margin: 0;
  left: 50%;
  transform: translateX(-50%);
  position: absolute;
  z-index: 10;

  .nav-inside {
    display: flex;
    width: 100%;
  }

  .logo {
    position: relative;
    width: 91px;
    height: 100px;
    margin-right: 77px;
    align-self: end;
    cursor: pointer;
    transition: 0.3s ease-out;
  }

  .links {
    display: flex;
    //max-width: 500px;

    a {
      font-size: 20px;
      color: #233E99;
      cursor: pointer;
      font-family: bpg-web-002-caps;
    }

    .link-box {
      padding: 75px 25px 18px 25px;
      border-bottom-left-radius: 37px;
      border-bottom-right-radius: 37px;
      cursor: pointer;

      a {
        color: #233E99 !important;
      }
    }

    .link-box.active {
      background: #233E99;

      a {
        color: white !important;
      }
    }

    .link-box.not-default:hover {
      background: #233E99;

      a {
        color: white !important;
      }
    }


  }

  .right-side {
    margin-left: auto;
    display: flex;
    align-items: flex-end;
    margin-bottom: 13px;

    .sign {
      display: flex;
      font-size: 20px;
      gap: 13px;

      &__up, &__in {
        width: 142px;
        height: 38px;
        border-radius: 19px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
      }

      &__up {
        color: white;
        border: 1px solid #233E99;
        background: #233E99;

      }

      &__in {
        color: #233E99;
        border: 1px solid #233E99;

      }
    }
  }

  .mobile-menu {
    display: none;

  }


}
.language {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 22px;
  width: 117px;
  height: 36px;
  font-size: 20px;
  border: 1px solid #707070;
  background: #233E99;
  border-radius: 28px;
  margin-left: 12px;
  color: white;
  transition: 0.3s ease-out;
  padding: 20px;
  font-family: FiraGO-Regular;
  cursor: pointer;

  p {
    margin: 0;
  }

  .active {
    background: white;
    color: #233E99;
    border-radius: 50%;
    padding: 2px 5px;
  }

}
.mobile-menu-canvas {
  background-color: #2840b9 !important;

  .offcanvas-body {
    padding: 0;

    .link-box {
      cursor: pointer;
      padding: 20px 0;

      a {
        color: white;
        cursor: pointer;
        margin-left: 15px;
      }

      &:hover {
        background-color: red;
      }
    }
  }

  .icon {
    margin-right: 20px;
    display: flex;
    justify-content: flex-end;
  }
}

@media only screen and (max-width: 1240px) {
  .nav {
    //padding: 0 15px !important; with signup/sign in

    .logo {
      margin-right: 25px;
    }

    .right-side {
      .sign {
        gap: 10px;
      }

      .sign__in, .sign__up {
        padding: 6px 20px;
        width: 114px;
        height: 36px;
        font-size: 18px;
      }

      .language {
        font-size: 20px;
        margin-left: 10px;

      }
    }

    .links {

      a {
        font-size: 18px;
      }

      .link-box {
        padding: 70px 20px 18px 20px;
      }
    }
  }

}


@media only screen and (max-width: 1000px) {
  .nav {
    padding: 34px 20px 54px 20px !important;

    .links, .right-side {
      display: none
    }

    .logo {
      width: 57px;
      height: 63px;
    }

    .mobile-menu {
      display: unset;
      width: 41px;
      height: 22px;
      margin-left: auto;
    }
  }
}
