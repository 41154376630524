.footer {
  height: 318px;
  background-color: #233E99;
  display: flex;
  position: relative;
  justify-content: center;
  transition: 0.3s ease-out;

  .row {
    display: flex;
    max-width: 1640px;
    width: 100%;
    padding: 26px 240px 0 20px;
    margin-bottom: 50px;
    transition: 0.3s ease-out;


    .col {
      display: flex;
      flex-direction: column;
      transition: 0.3s ease-out;


      &.one {
        width: 30%;
        min-width: 293px;
        transition: 0.3s ease-out;

      }

      &.two {
        width: 25%;
        min-width: 264px;
        transition: 0.3s ease-out;

      }

      &.three {
        width: 25%;
        z-index: 2;
        transition: 0.3s ease-out;

      }

      &.four {
        width: 20%;
        transition: 0.3s ease-out;

      }

    }
  }
}

.footer-logo-box {
  display: flex;
  margin-bottom: 31px;
  align-items: center;
  transition: 0.3s ease-out;

  .footer-logo{
    position: relative;
    width: 91px;
    height: 91px;

  }

  p {
    margin-left: 15px;
    margin-bottom: 0;
    color: white;
    font-size: 32px;
    transition: 0.3s ease-out;
    font-family: bpg-web-002-caps;

  }

  &__info {
    max-width: 293px;
    color: white;
    font-size: 13px;
    transition: 0.3s ease-out;

  }
}

.footer-contact-info {
  color: white;
  font-size: 22px;
  margin-top: 51px;
  margin-bottom: 30px;
  transition: 0.3s ease-out;

  &__phone:hover{
    color: white;
  }
  &__location, &__phone {
    font-size: 16px;
    color: white;
    transition: 0.3s ease-out;
  }

  &__phone {
    margin-top: 20px;

  }

}

.footer-social-media-icons {
  display: flex;
  margin-top: 58px;
  align-items: center;
  gap: 21px;
  transition: 0.3s ease-out;


  .icon {
    position: relative;
    width: 36px;
    height: 36px;
    cursor: pointer;
    transition: 0.3s ease-out;

  }

  .icon:last-child {
    width: 43px;
    height: 33px;
    transition: 0.3s ease-out;

  }
}

.footer-map {
  width: 389px;
  height: 215px;
  margin-top: 26px;
  border-radius: 33px;
  z-index: 2;
  transition: 0.3s ease-out;
  overflow: hidden;

}

.footer-guy-container {
  overflow: hidden;
  width: 414px;
  position: absolute;
  right: 0;
  bottom: 15px;
  height: 510px;
  //transition: 0.3s ease-out;

  .news-hand{
    position: relative;
    left: 140px;
    bottom: 0;
    //transition: 0.3s ease-out;
    width: 297px;
    height: 316px;
  }

}

.footer-guy-container.news {
  bottom: 0;
  width: 296px;

}


.footer-guy {
  width: 486px;
  height: 486px;
  position: absolute;
  right: -72px;
  bottom: 15px;
  z-index: 1;
  transition: 0.3s ease-out;

}


.mobile-footer {
  display: none;
}

@media only screen and (max-width: 1290px) {
  .footer {
    height: 290px;
    transition: 0.3s ease-out;
  }
  .footer .row {
    padding: 26px 170px 0 20px;
    margin-bottom: 0;
    transition: 0.3s ease-out;

  }

  .footer-guy-container {

    .news-hand{
      left: 250px;
      //transition: 0.3s ease-out;
    }

  }

  .footer-guy-container.news {
    width: 250px;
    height: 450px;

    .ladder {
      width: 250px;
      height: 450px;
    }
  }

  .footer-logo-box__info {
    max-width: 195px;
    color: white;
    font-size: 11px;
    transition: 0.3s ease-out;
    font-family: bpg-web-002-caps;

  }
  .footer-logo-box {

    .footer-logo {
      width: 70px;
      height: 70px;
      transition: 0.3s ease-out;

    }

    p {
      font-size: 24px;
      transition: 0.3s ease-out;

    }
  }

  .footer-contact-info {
    font-size: 18px;
    margin-top: 47px;
    transition: 0.3s ease-out;
    font-family: bpg-web-002-caps;
  }

  .footer-contact-info__location, .footer-contact-info__phone {
    font-size: 11px;
    color: white;
    transition: 0.3s ease-out;
    font-family: bpg-web-002-caps;
    width: fit-content;

  }
  .footer-contact-info__phone {
    margin-top: 15px;
    transition: 0.3s ease-out;

  }
  .footer-social-media-icons .icon {
    width: 24px;
    height: 24px;
    transition: 0.3s ease-out;


    &:last-child {
      width: 30px;
      height: 23px;
      transition: 0.3s ease-out;

    }
  }
  .footer-social-media-icons {
    gap: 14px;
    margin-top: 48px;
    transition: 0.3s ease-out;

  }
  .footer-map {
    width: 349px;
    height: 192px;
    transition: 0.3s ease-out;

  }

  .footer .row .col {

    &.one {
      width: 30%;
      min-width: 220px;
      transition: 0.3s ease-out;
      padding-left: 0;
    }

    &.two {
      width: 25%;
      min-width: 200px;
      transition: 0.3s ease-out;

    }

    &.three {
      width: 20%;
      min-width: 160px;
      z-index: 2;
      transition: 0.3s ease-out;

    }

    &.four {
      width: 25%;
      transition: 0.3s ease-out;

    }

  }
  .footer-guy {
    width: 426px;
    height: 426px;
    transition: 0.3s ease-out;

  }
}

@media only screen and (max-width: 1000px) {
  .footer {
    display: none
  }

  .mobile-footer {
    height: 239px;
    background-color: #233E99;
    display: flex;
    position: relative;
    padding-left: 17px;

    .logo {
      position: relative;
      width: 91px;
      height: 91px;
      margin-right: 30px;
      margin-top: 75px;
    }
  }

  .footer-contact-info {
    font-size: 17px;
    margin-top: 68px;
    margin-bottom: 23px;

    &__location, &__phone {
      font-size: 10px;
      color: white;
    }

    &__phone {
      margin-top: 20px;
    }

  }

  .footer-guy-container {
    overflow: hidden;
    width: 175px;
    position: absolute;
    right: 0;
    bottom: 0;
    height: 200px;
  }

  .footer-guy {
    width: 200px;
    height: 200px;
    position: absolute;
    bottom: 0;
    right: -28px;
    z-index: 1;

  }

  .footer-social-media-icons.mobile {
    align-items: flex-start;
    margin-top: 70px;
    margin-left: 30px;
  }
}

@media only screen and (max-width: 640px) {
  .footer-social-media-icons.mobile {
    display: none;
  }
}

@media only screen and (max-width: 428px) {
  .footer-guy {
    width: 170px;
    height: 170px;
    transition: 0.3s ease-out;
  }
  .mobile-footer > .logo {
    margin-right: 20px;
  }
}
