.circle {
  width: 64px;
  height: 64px;
  border-radius: 50%;
  position: absolute;

  svg {
    left: -9px;
    position: absolute;
    top: -6px;

    g > g, g {
      .a {
        transition: 0.3s ease-out;
      }

      .b {
        transition: 0.3s ease-out;
      }
    }

    &:hover g > g, &:hover g {
      .a {
        fill: white;
        transition: 0.3s ease-out;
      }

      .b {
        fill: #233E99;
        transition: 0.3s ease-out;

      }
    }

  }

  p {
    bottom: -22px;
    position: relative;
    right: 356px;
    font-size: 15px;
    color: black;
    text-shadow: 0 3px 6px #00000061;
    text-align: end;
    width: 330px;
    opacity: 0.17;
    transition: 0.3s ease-out;
    pointer-events: none;
    font-family: bpg-web-002-caps;
  }

  &:hover > p{
    color: #233E99;
    opacity: 1;
    transition: 0.3s ease-out;
  }

  &.one {
    left: 93px;
  }

  &.two {
    top: 110px;
    left: 29px;
  }

  &.three {
    top: 220px;
  }

  &.four {
    top: 330px;
    left: 29px;

  }

  &.five {
    top: 440px;
    left: 93px;
  }

  &.six {
    right: 126px;
    bottom: 6px;
  }

  &.seven {
    right: 0;
    bottom: 0;
  }
}

.swiper-box > .content__image.admin-simulation {
  z-index: 2;
  border-radius: 54px;
  transform: scale(1.65605);
  box-shadow: 0 3px 20px rgb(0 0 0 / 63%);
}

@media only screen and (max-width: 1000px) {
  .circle {
    p {
      display: none;
    }
  }
}
