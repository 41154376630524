.swiper-container {
  display: flex;
  align-items: center;
  position: relative;
  justify-content: center;
  height: 600px;

  .partner-name {
    position: absolute;
    top: 0;
    z-index: 2;
    font-size: 40px;
    color: #1F2023;
    padding: 10px 42px;
    box-shadow: 0 3px 6px #0000005C;
    border-radius: 24px;
  }

  .top-line {
    height: 27px;
    background-color: #707070;
    position: absolute;
    width: 1px;
    top: 122px;
    z-index: 2;
  }

  .bot-line {
    height: 94px;
    background-color: #707070;
    position: absolute;
    width: 1px;
    bottom: 54px;
    z-index: 2;
  }


  .background {
    position: absolute;
    width: 100%;
    background-color: #233E99;
    border: 1px solid #707070;
    height: 200px;
    &>*{
      opacity: 0.2 !important;
    }
  }

  .swiper-box {
    align-items: center;
    justify-content: center;
    max-width: 1600px;
    display: flex;
    width: calc(100% - 202px);

    .prev {
      z-index: 1;
      color: white;
      font-size: 20px;
      margin-right: 40px;
      margin-left: 20px;
      transform: scaleX(-1);
      cursor: pointer;
      width: 21px;
      height: 44px;
    }

    .next {
      z-index: 1;
      color: white;
      font-size: 20px;
      margin-right: 20px;
      margin-left: 40px;
      cursor: pointer;
      width: 21px;
      height: 44px;
    }

    .two-swiper {
      align-items: center;
      justify-content: center;
      max-width: 1300px;
      width: 100%;
    }

    .partners-swiper a{
      overflow: hidden;
    }

    .mySwiper {
      height: 320px;
      display: flex;
      align-items: center;
      margin: 0 !important;

      .swiper-wrapper {
        height: 50% !important;


        .swiper-slide > * {
          border-radius: 54px;
          transition: 0.5s ease-out;

        }

        .swiper-slide-active > * {
          transition: 0.5s ease-out;
          transform: scale(1.65605);
          box-shadow: 0 3px 20px #000000A1;
        }

        .swiper-slide-prev {
          //padding-right: 40px;

        }

        .swiper-slide-next {
          //padding-left: 40px;

        }
      }
    }
  }


  .company-description {
    position: absolute;
    top: 560px;
    padding: 36px 96px 53px 31px;
    max-width: 1036px;
    box-shadow: 0 3px 6px #00000059;
    border-radius: 61px;
    margin: 0 30px;
    background-color: rgba(255, 255, 255, 0.57);
    backdrop-filter: blur(5px);
    p {
      color: #1F2023;
      font-size: 23px;
      line-height: 1.31;
      margin: 0;
      font-family: cursive;
    }
  }

}

.swiper-slide {
  transition: 0.5s ease-out;
  justify-content: center;
  display: flex;
}

.mySwiper2 {
  display: none;
}


@media only screen and (max-width: 800px) {

  .mySwiper2 {
    display: flex;
  }
  .top-line, .bot-line, .partner-name, .company-description {
    display: none;
  }
  .two-swiper {
    position: relative;
    min-width: 285px;
  }
  .mySwiper {
    .swiper-wrapper {
      .swiper-slide-active > * {
        transform: scale(1.47135) !important;
        box-shadow: 0 3px 20px #000000A1;
      }
    }
  }
  .mySwiper2 {
    position: absolute;
    min-width: 340px;
    left: -28px;

    .swiper-wrapper {
      .swiper-slide > * {
        border-radius: 54px;
      }
    }
  }
  .swiper-box {
    max-width: 290px !important;

    .prev {
      margin-right: 0 !important;
      margin-left: 0 !important;
      margin-bottom: 60px;
    }

    .next {
      margin-left: 0 !important;
      margin-right: 0 !important;
      margin-bottom: 60px;
    }
  }
  .swiper-container .background {
    height: 491px !important;
    bottom: 20px !important;
    &>*{
      display: none !important;
    }
  }

  .mySwiper2 .swiper-slide {
    opacity: 0.4;
  }

  .mySwiper2 .swiper-slide-thumb-active {
    opacity: 1;
  }


}
