.projects-page {
  position: relative;
  flex: 1;
  z-index: 2;

  .grid {
    max-width: 1800px;
    min-height: 460px;
    margin: 275px auto 50px auto;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(410px, 1fr));
    gap: 25px;

    .HomePageCard {
      margin: auto;
      div {
        width: 100%;
      }
    }
  }

  .background {
    z-index: -1;
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;

    img {
      width: 3370px;
      height: inherit;
      position: relative;
      left: -370px;
      top: 0;
    }
  }
}

.project-edit-img-container {
  display: flex;
  gap: 30px;
  flex-wrap: wrap;
}

.project-edit-img {
  width: fit-content;
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 10px;
  border: 2px solid green;
  border-radius: 20px;
  padding: 5px;

  &.delete {
    border-color: red;
  }

  &__button {
    display: flex;
    justify-content: space-evenly;

  }
}

.arrow-right{
  border: solid #233E99;
  border-width: 0px 4px 4px 0;
  display: inline-block;
  padding: 5px;
  transform: rotate(-45deg) scale(0.7);
  -webkit-transform: rotate(-45deg) scale(0.7) translateY(3px);
}

@media only screen and (max-width: 1000px) {
  .projects-page {
    .grid {
      margin-top: 150px;
    }
  }
}

@media only screen and (max-width: 850px) {

  .projects-page {
    .grid {
      max-width: 390px;
      justify-content: center;
    }
  }

}
